import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/inc/Navbar';
import Footer from './components/inc/Footer';
import Error from './components/pages/Error';
import Resume from './components/pages/Resume';
import Signup from './components/pages/SignUp';
import Login from './components/pages/Login';
import ProtectedRoute from './ProtectedRoute'; // Import your ProtectedRoute component

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/resume" element={<ProtectedRoute path="/resume"
            element={<Resume />} />} />
          
          
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
